import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, GuardResult, MaybeAsync, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, tap } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({ providedIn: 'root' })
export class AppAccessGuard implements CanActivate, CanLoad {
  isAuthorized: boolean;

  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) { }

  /**
   * Determines if the route can be loaded.
   * @param route The route to be loaded.
   * @param segments The URL segments.
   * @returns An observable or boolean indicating if the route can be loaded.
   */
  canLoad(route: Route, segments: UrlSegment[]): MaybeAsync<GuardResult> {
    this.authService
      .getCurrentUserAppAccess()
      .subscribe(data => {
        console.dir(data);
        this.isAuthorized = data?.response?.isAuthorized;
        if (!this.isAuthorized) {
          this.router.navigate(['unauthorized']);
          return of(this.isAuthorized);
        } else {
          return of(this.isAuthorized);
        }
      });
    return this.isAuthorized;
  }

  /**
   * Determines if the route can be activated.
   * @param route The activated route snapshot.
   * @param state The router state snapshot.
   * @returns An observable, promise, or boolean indicating if the route can be activated.
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.authService
      .getCurrentUserAppAccess()
      .pipe(tap(data => {
        this.isAuthorized = data?.response?.isAuthorized;
        if (!this.isAuthorized) {
          this.router.navigate(['/unauthorized']);
          return this.isAuthorized;
        } else {
          return this.isAuthorized;
        }
      }));
    return this.isAuthorized;
  }
}
