import { Component, HostListener, inject } from '@angular/core';
import { AuthenticationService } from './core/services/authentication.service';
import { environment } from '../environments/environment';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { filter } from 'rxjs';

import { EventMessage, EventType } from '@azure/msal-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'  
})
export class AppComponent {
  title = 'OneCustomer';
  loggedOutStatus: any;
  loginDisplay: boolean = false;
  status: any;
  isMsalLoggedIn: boolean = false;

  @HostListener('document:click', ['$event']) onDocumentClick(event: Event) {
    this.authService.checkLoggedOutService().subscribe((info) => {
      this.status = info?.data?.login?.isLoggedOut;
      if (this.status) {
        this.authService.logoutTVDAPP(true);
        this.msal.logoutRedirect({
          postLogoutRedirectUri: environment.ECOLAB_WEB,
        });
        this.msal.logout();
      }
    });
  }
  constructor(private authService: AuthenticationService,
     private msal: MsalService, private msalBroadcastService: MsalBroadcastService) { 
    this.isMsalLoggedIn = false;
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => {
          return msg.eventType === EventType.LOGIN_SUCCESS
        }),
      )
      .subscribe((result: EventMessage) => {
        this.isMsalLoggedIn = true;
        
        this.authService.updateUserLoginStatus
         (
            this.msal.instance.getAllAccounts()[0].localAccountId,
            false).subscribe({});
      });
  }

  ngOnInit(): void {
    if (!this.isMsalLoggedIn) {
      /*
      check user status from data source.
      IF user state = Logged out, force the user to log-out
      ELSE continue the user session
       */
      this.authService.checkLoggedOutService().subscribe(info => {
        this.status = info?.data?.login?.isLoggedOut;
        if (this.status) {
          this.authService.logoutTVDAPP(true);
        }
      })
    }

  }
  }

  
