import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../../core/services/authentication.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppAccessResolver implements Resolve<any> {
  constructor(
    private authService: AuthenticationService
  ) { }

  /**
   * Resolves the current user's app access before activating the route.
   * @param route The activated route snapshot.
   * @param state The router state snapshot.
   * @returns An observable containing the user's app access data.
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.authService.getCurrentUserAppAccess(); // Perform API call here
  }
}
