import { Component, input } from '@angular/core';
import { ECOLAB_URL} from '../../shared/constants/end-point-constants'
import {unauthorized_icon , ECLOLAB } from '../../shared/constants/app.constants';
import { TranslationService } from '../../features/tvd-setup/services/translation.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-unauthorized-user',
  templateUrl: './unauthorized-user.component.html',
  styleUrl: './unauthorized-user.component.scss'
})
export class UnauthorizedUserComponent {
  //heading: string = '';
  //ECOLAB_URL = ECOLAB_URL;
  UnAuthICON = ECLOLAB;
  constructor(
    public translateService: TranslationService,
    public translate: TranslateService
  ) {}
  returnToMain() {
    window.location.href = "https://www.ecolab.com";
  }
  ngOnInit() {
    this.translateService.selectedLanguage();
  }
}
